import React from 'react';
import { Button } from 'react-md';
import { ExitLeftIcon } from '../assets/react-icons';

const SignOutButton = ({ signOut, mobile }) => {

  function handleSignOut() {
    signOut();
  };

  return (
    <Button onClick={handleSignOut} flat className={!mobile ? 'user-bottom-menu-items' : 'mobile-sign-out-button'}>
      <div className={!mobile ? 'user-bottom-menu-icon' : 'mobile-sign-out-icon'}>
        <ExitLeftIcon height="24px" width="24px" color="rgba(0,0,0,.5)" />
      </div>
      <div className={mobile && 'md-tile-text--primary'}>Sign out</div>
    </Button>
  );
};


export default SignOutButton;
