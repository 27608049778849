import React from 'react';
import './AppDrawerButton.scss';
import HeaderDropDown from '../HeaderDropDown/HeaderDropDown';
import { AppDrawerIcon } from '../assets/react-icons';
import AppMenu from '../AppMenu/AppMenu';

const AppDrawerButton = ({ className, buttonColor }) => {
  return (
    <div id="app-dropdown-button" className={className}>
      <HeaderDropDown dropDownContent={<AppMenu />} idName="app-dropdown">
        <AppDrawerIcon color={buttonColor} width="24px" />
      </HeaderDropDown>
    </div>
  );
};

export default AppDrawerButton;
