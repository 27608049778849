import React from 'react';
import PropTypes from 'prop-types';
import { DialogContainer } from 'react-md';
import Timer from 'react-compound-timer';

const SessionDialog = ({ visible, onHide, onContinue, onSignOut, onTimeExpire, message, duration }) => {
  function handleContinue() {
    if (onContinue) {
      onContinue();
    }
    onHide();
  }

  function handleSignOut() {
    if (onSignOut) {
      onSignOut();
    }
    onHide();
  }

  return (
    <DialogContainer
      id="session-dialog"
      visible={visible}
      onHide={onHide}
      aria-describedby="session expire"
      modal
      portal
      lastChild
      actions={[{
        onClick: handleSignOut,
        primary: true,
        children: 'Sign out',
      }, {
        onClick: handleContinue,
        primary: true,
        children: 'Continue',
      }]}
    >
      <p id="session expire" className="md-color--secondary-text">
        {message}
        <br />
        <Timer
          initialTime={duration} // 60 seconds
          direction="backward"
          checkpoints={[
            {
              time: 0,
              callback: () => onTimeExpire(),
            }
          ]}
        >
          <Timer.Seconds /> seconds
        </Timer>
      </p>
    </DialogContainer>
  );
};

SessionDialog.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
  onContinue: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  onSignOut: PropTypes.func,
  onTimeExpire: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

SessionDialog.defaultProps = {
  duration: 60000,
  message: 'Your session is about to expire!',
  visible: false
};

export default SessionDialog;
