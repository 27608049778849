export const LEGACY_PORTAL = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const LIVE = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const ANALYTICS = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const SCHEDULER = ['GLOBAL_ADMIN'];
export const API = ['GLOBAL_ADMIN'];
export const DEVICES = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const EDUCATION = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const VIP_MANAGER = ['GLOBAL_ADMIN', 'OPERATOR', 'VIEWER'];
export const PREPAID_PASS_MANAGER = ['GLOBAL_ADMIN'];
export const API_PROVISIONING = ['GLOBAL_ADMIN'];
export const USERS = ['GLOBAL_ADMIN'];
export const NETWORK_DIAGRAM = ['GLOBAL_ADMIN'];
