import React, { useContext } from 'react';
import AppDrawerButton from '../AppDrawerButton/AppDrawerButton';
import UserButton from '../UserButton/UserButton';
import { HeaderContext } from './Header.context';

const DesktopHeader = ({ logoClick, logoSrc, logoWidth, backgroundColor }) => {
  const { authUser, buttonColor, appMenuActive } = useContext(HeaderContext);

  function handleLogoClick() {
    if (logoClick) {
      logoClick();
    }
  }

  return (
    <>
      <header id="header" className="header-desktop" style={{ backgroundColor: backgroundColor }}>
        <div className="logo-container" onClick={()=> handleLogoClick()}>
          <img src={logoSrc} alt="parkhub logo" width={logoWidth} />
        </div>
        <div className="desktop-header-actions">
          {!!authUser &&
            <>
              {appMenuActive &&
                <AppDrawerButton
                  id="desktop-header-app-drawer"
                  className="desktop-header-action-child"
                  buttonColor={buttonColor}
                />
              }
              <UserButton
                id="desktop-header-user-menu"
                className="desktop-header-action-child"
                authUser={authUser}
              />
            </>
          }
        </div>
      </header>
    </>
  );
};

export default DesktopHeader;
