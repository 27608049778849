import React from 'react';
import './UserButton.scss';
import UserMenu from '../UserMenu/UserMenu';
import HeaderDropDown from '../HeaderDropDown/HeaderDropDown';

const UserButton = ({ authUser, className }) => {

  return (
    <div id="user-dropdown-button" className={className}>
      <HeaderDropDown
        dropDownContent={
          <UserMenu />
        }
        idName="user-dropdown"
      >
        <div className="user-avatar">{authUser && `${authUser.fn[0]}${authUser.ln[0]}`}</div>
      </HeaderDropDown>
    </div>
  );
};

export default UserButton;
