import './HeaderDropDown.scss';
import React from 'react';
import { MenuButton } from 'react-md';

const HeaderDropDown = ({ idName, children, dropDownContent }) => {
  return (
    <MenuButton
      id={idName || 'header-dropdown'}
      flat
      simplifiedMenu={false}
      className="header-menu-btn"
      primary
      transitionEnterTimeout={0}
      listHeightRestricted={false}
      listStyle={{
        top: '76px',
        right: '12px',
        left: 'auto'
      }}
      menuItems={dropDownContent}
      repositionOnScroll={false}
    >
      {children}
    </MenuButton>
  );
};

export default HeaderDropDown;
