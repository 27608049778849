import React from 'react';
import './GhostingView.scss';
import { FontIcon } from 'react-md';

export default function GhostingView (props) {
  return (
    <div className="ghosting-info">
      <FontIcon>person</FontIcon>
      <div className="viewing-as">Viewing as {props.authUser.fn} {props.authUser.ln}</div>
    </div>
  );
}
